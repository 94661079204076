import { recalculateScroll } from './menu.js'
;(function(d, w) {
  var $ = id => d.getElementById(id)
  var modal = $('theme-modal')

  if (!modal) {
    return
  }

  var body = d.body
  var html = d.querySelector('html')
  var wrap = $('theme-wrap')
  var link = $('theme-link')
  var iconClose = $('icon-close')
  var iconDesktop = $('icon-desktop')
  var iconMobile = $('icon-mobile')
  var signupButton = $('theme-signup')
  var filters = $('theme-filter')
  var filtersSelect = $('theme-filter-select')
  var themeList = $('theme-list')
  var themes = $('themes')
  var isOpen = false
  var isMobile = null
  var isNarrow = null
  var currentIndex = null
  var scrollY = 0
  var iframe

  w.addEventListener('resize', onResize, { passive: true })
  w.addEventListener('scroll', onScroll, { passive: true })
  w.addEventListener('popstate', onPopState)
  d.addEventListener('click', function(event) {
    var svg = event.target.closest('svg')
    if (!svg) return

    if (svg.classList.contains('icon__desktop')) {
      setMobile(false)
    } else if (svg.classList.contains('icon__mobile')) {
      setMobile(true)
    }
  })

  if (filters) {
    filters.addEventListener('click', onFilter)

    if (history.state && history.state.category) {
      toggleFilter(filters.querySelector('[data-id=' + history.state.category + ']'))
      changeFilter(history.state.category, false)
    }
  }

  if (filtersSelect) {
    filtersSelect.addEventListener('change', onFilterSelect)

    if (history.state && history.state.category) {
      filtersSelect.value = history.state.category
    }
  }

  function onFilter(event) {
    var target = event.target
    while (target !== filters) {
      if (target.hasAttribute('data-id')) {
        break
      }
      target = target.parentNode
    }
    if (target === filters) {
      return
    }
    if (target.classList.contains('--active')) {
      return
    }
    toggleFilter(target)
    var category = target.getAttribute('data-id')
    changeFilter(category)
    historyPush({ category: category })
  }

  function onFilterSelect() {
    var category = event.target.value
    changeFilter(category)
    historyPush({ category: category })
  }

  function toggleFilter(next) {
    var previous = filters.querySelector('.--active')
    if (previous) {
      previous.classList.remove('--active')
    }
    next.classList.add('--active')
  }

  function changeFilter(category, withScroll = true) {
    Array.from(themeList.children).forEach(function(theme) {
      var show
      if (category) {
        var id = parseInt(theme.getAttribute('data-id'), 10)
        show = __themeCategories__[category] && __themeCategories__[category].includes(id)
      } else {
        show = true
      }
      theme.classList.add('show-on-scroll')
      theme.classList.remove('keep-on-scroll')
      theme.classList.remove('is-visible')
      if (show) {
        theme.style.display = ''
      } else {
        theme.style.display = 'none'
      }
    })

    recalculateScroll()

    if (withScroll) {
      themes.scrollIntoView({ behavior: 'auto', block: 'start' })
    }
  }

  function resize() {
    if (isOpen) {
      if (isMobile) {
        iframe.style.transform = iframe.style.height = iframe.style.width = ''
      } else {
        var scale = window.innerWidth / 1920
        var inverse = 100 / scale
        iframe.style.transform = 'scale(' + scale + ')'
        iframe.style.height = inverse + '%'
        iframe.style.width = inverse + '%'
      }
    }
  }

  function onResize() {
    isNarrow = window.innerWidth <= 990
    if (isNarrow) {
      if (isMobile !== true) {
        setMobile(true)
      }
    } else {
      if (isMobile !== false) {
        setMobile(false)
      }
    }
    resize()
  }

  function setMobile(nextIsMobile) {
    isMobile = nextIsMobile
    if (isMobile) {
      modal.classList.add('tmodal--mobile')
      modal.classList.remove('tmodal--desktop')
    } else {
      modal.classList.add('tmodal--desktop')
      modal.classList.remove('tmodal--mobile')
    }
    if (isOpen) {
      open(currentIndex, true)
    }
  }

  var iframe = document.createElement('iframe')
  iframe.setAttribute('frameborder', 0)
  iframe.setAttribute('scrolling', 'yes')

  function getIframeView() {
    if (isMobile) return ''
    if (isNarrow) return '?view=limit'
    return '?view=padding'
  }

  function open(index, noPush) {
    currentIndex = index
    isOpen = true
    html.style.scrollBehavior = 'auto'
    body.style.top = scrollY + 'px'
    body.style.position = 'fixed'
    modal.style.display = 'block'
    modal.style.backgroundColor = __themes__[index].background
    wrap.appendChild(iframe)
    var url = 'https://' + __themes__[index].url + '.saywebpage.com/' + __themeLang__
    iframe.src = url + getIframeView()
    link.href = url
    link.style.color = __themes__[index].textcolor
    iconClose.style.fill = __themes__[index].textcolor
    iconDesktop.style.fill = __themes__[index].textcolor
    iconMobile.style.fill = __themes__[index].textcolor
    signupButton.setAttribute('data-theme', __themes__[index].id)
    resize()

    if (noPush !== true) {
      historyPush({ index: index })
    }
  }

  function close(noPush) {
    currentIndex = null
    isOpen = false
    body.style.top = ''
    body.style.position = ''
    modal.style.display = 'none'
    link.href = iframe.src = 'about:blank'
    wrap.removeChild(iframe)
    w.scrollTo(0, -scrollY)
    html.style.scrollBehavior = ''

    if (noPush !== true) {
      historyPush({})
    }
  }

  function onScroll(e) {
    if (!isOpen) {
      scrollY = -w.scrollY
    }
  }

  function historyPush(state) {
    try {
      if (!('category' in state) && w.history.state && w.history.state.category) {
        state.category = w.history.state.category
      }
      w.history.pushState(state, d.title, w.location)
    } catch (e) {}
  }

  function onPopState(event) {
    if (event.state && event.state.index) {
      if (event.state.index !== currentIndex) {
        open(event.state.index, true)
      }
    } else if (isOpen) {
      close(true)
    }

    if (event.state && event.state.category) {
      changeFilter(event.state.category)
    } else {
      changeFilter()
    }
  }

  onResize()
  w.openThemeModal = open
  w.closeThemeModal = close
})(document, window)
