export function createStore(initialState, reducer) {
  const listeners = []
  let state = reducer(initialState, { type: '__INIT__ ' })

  return {
    dispatch(action) {
      state = reducer(state, action)
      listeners.forEach(listener => listener(state, action))
    },
    subscribe(listener) {
      listeners.push(listener)
      return function unsubscribe() {
        listeners = listeners.filter(l => l !== listener)
      }
    },
    getState() {
      return state
    },
  }
}
