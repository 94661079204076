import { h, render, Fragment } from 'preact'
import { useState } from 'preact/hooks'

const form = document.getElementById('referral-signup')

if (form) {
  const { signupUrl, baseUrl } = window.INITIAL_STATE
  function StepA() {
    return (
      <div>
        <div class="text-box w--700 mt--02 text-primary ">
          <p class="text">
            <span>Krok 1 ze 2: Osobní údaje</span>
          </p>
        </div>
        <div class="login__wrap-box--half mt--30">
          <label class="login__box">
            <input class="login__input login__input--fit" type="text" name="given_name" required />
            <span class="login__label">
              <span class="login__name login__name--fit">Jméno</span>
            </span>
          </label>
          <label class="login__box">
            <input class="login__input login__input--fit" type="text" name="family_name" required />
            <span class="login__label">
              <span class="login__name login__name--fit">Příjmení</span>
            </span>
          </label>
        </div>
        <label class="login__box mt--16">
          <input class="login__input login__input--fit" type="email" name="email" required />
          <span class="login__label">
            <span class="login__name login__name--fit">E-mailová adresa</span>
          </span>
        </label>
        <label class="login__box mt--16">
          <input class="login__input login__input--fit" type="text" name="phone" required />
          <span class="login__label">
            <span class="login__name login__name--fit">Telefonní číslo</span>
          </span>
        </label>
        <label class="login__box mt--16">
          <input class="login__input login__input--fit" type="text" name="street" required />
          <span class="login__label">
            <span class="login__name login__name--fit">Ulice a číslo popisní</span>
          </span>
        </label>
        <div class="login__wrap-box--half mt--16">
          <label class="login__box">
            <input class="login__input login__input--fit" type="text" name="city" required />
            <span class="login__label">
              <span class="login__name login__name--fit">Město</span>
            </span>
          </label>
          <label class="login__box">
            <input
              class="login__input login__input--fit"
              type="number"
              name="postal_code"
              required
            />
            <span class="login__label">
              <span class="login__name login__name--fit">PSČ</span>
            </span>
          </label>
        </div>
        <label class="login__box mt--16">
          <input
            class="login__input login__input--fit"
            type="text"
            name="country"
            value="CZE"
            required
            disabled
          />
          <span class="login__label">
            <span class="login__name login__name--fit">Země</span>
          </span>
        </label>
        <div class="btn-box btn-box--primary btn-box--fit mt--30">
          <button type="submit" class="btn btn--m-full">
            Pokračovat
          </button>
        </div>
      </div>
    )
  }

  function StepB() {
    const [type, setType] = useState(null)
    return (
      <div>
        <div class="text-box w--700 mt--02 text-primary ">
          <p class="text">
            <span>Krok 2 ze 2: Fakturační údaje</span>
          </p>
        </div>
        <div class="login__wrap-box--third login__wrap-box--menu mt--16">
          <div class="text-box">
            <p class="text">Fakturovat jako</p>
          </div>
          <label class="login__box">
            <input
              class="login__input login__input--radio"
              type="radio"
              name="billtype"
              value="Osoba"
              checked={type === 'osoba'}
              onChange={() => setType('osoba')}
            />
            <span class="login__label">
              <span class="login__name login__name--fit">Osoba</span>
            </span>
          </label>
          <label class="login__box">
            <input
              class="login__input login__input--radio"
              type="radio"
              name="billtype"
              value="Firma"
              checked={type === 'firma'}
              onChange={() => setType('firma')}
            />
            <span class="login__label">
              <span class="login__name login__name--fit">Firma</span>
            </span>
          </label>
        </div>
        {type !== 'osoba' ? (
          <Fragment>
            <label class="login__box mt--30">
              <input
                class="login__input login__input--fit"
                type="text"
                name="company_street"
                required
              />
              <span class="login__label">
                <span class="login__name login__name--fit">Ulice a číslo popisné</span>
              </span>
            </label>
            <div class="login__wrap-box--half mt--16">
              <label class="login__box">
                <input class="login__input login__input--fit" type="text" name="company_city" />
                <span class="login__label">
                  <span class="login__name login__name--fit">Město</span>
                </span>
              </label>
              <label class="login__box">
                <input
                  class="login__input login__input--fit"
                  type="number"
                  name="company_postal_code"
                  required
                />
                <span class="login__label">
                  <span class="login__name login__name--fit">PSČ</span>
                </span>
              </label>
            </div>
            <label class="login__box mt--16">
              <input
                class="login__input login__input--fit"
                type="text"
                name="company_country"
                value="CZE"
                required
                disabled
              />
              <span class="login__label">
                <span class="login__name login__name--fit">Země</span>
              </span>
            </label>
            <div class="login__wrap-box--half mt--16">
              <label class="login__box">
                <input
                  class="login__input login__input--fit"
                  type="text"
                  name="registration_number"
                  required
                />
                <span class="login__label">
                  <span class="login__name login__name--fit">IČ</span>
                </span>
              </label>
              <label class="login__box">
                <input
                  class="login__input login__input--fit"
                  type="text"
                  name="tax_number"
                  required
                />
                <span class="login__label">
                  <span class="login__name login__name--fit">DIČ</span>
                </span>
              </label>
            </div>
          </Fragment>
        ) : null}
        <div class="text-box text-box--smaller mt--25">
          <p class="text">
            Registrací a vytvořením stránek souhlasíte s našimi
            <a href={`${baseUrl}cs/obchodni-podminky/`} target="_blank">
              obchodními podmínkami
            </a>
            a
            <a href={`${baseUrl}cs/zasady-ochrany-osobnich-udaju/`} target="_blank">
              pravidly ochrany soukromí
            </a>
            .
          </p>
        </div>
        <div class="btn-box btn-box--primary btn-box--fit mt--25">
          <button class="btn btn--m-full" type="submit">
            Registrovat a vytvořit účet
          </button>
        </div>
      </div>
    )
  }

  function HiddenStepA(props) {
    return (
      <Fragment>
        <input type="hidden" name="given_name" value={props.given_name} />
        <input type="hidden" name="family_name" value={props.family_name} />
        <input type="hidden" name="email" value={props.email} />
        <input type="hidden" name="phone" value={props.phone} />
        <input type="hidden" name="street" value={props.street} />
        <input type="hidden" name="postal_code" value={props.postal_code} />
        <input type="hidden" name="country" value={props.country} />
      </Fragment>
    )
  }

  function Form() {
    const [step, setStep] = useState('A')
    const [stepAValues, setStepAValues] = useState({})
    const onSubmit = function(event) {
      if (step === 'B') return
      event.preventDefault()
      const {
        given_name,
        family_name,
        email,
        phone,
        street,
        postal_code,
        country,
      } = event.target.elements
      setStepAValues({
        given_name: given_name.value,
        family_name: family_name.value,
        email: email.value,
        phone: phone.value,
        street: street.value,
        postal_code: postal_code.value,
        country: country.value,
      })
      setStep('B')
    }
    return (
      <form onSubmit={onSubmit} action={signupUrl} method="post">
        <input type="hidden" name="language" value="cs" />
        <input type="hidden" name="redirect" value="referral" />
        <input type="hidden" name="referral" value="1" />
        <input type="hidden" name="_do" value="signUpForm-submit" />
        <div class="log__content pb--80">
          <div class="subtitle-box">
            <h1 class="subtitle">Vytvořit partnerský účet</h1>
          </div>
          {step === 'A' ? <StepA /> : <HiddenStepA {...stepAValues} />}
          {step === 'B' ? <StepB /> : null}
        </div>
      </form>
    )
  }

  render(<Form />, form)
}
