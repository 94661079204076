import isElementInViewport from './util/isInViewport'

var parallaxes = document.querySelectorAll('.js-parallax')

if (parallaxes.length) {
  function onScroll() {
    window.requestAnimationFrame(() => {
      const inViewport = []
      for (const parallax of parallaxes) {
        const position = isElementInViewport(parallax)
        if (position.isInViewport) {
          let center = (position.top + position.bottom) / (3 * position.height)
          if (center > 1) center = 1
          if (center < 0) center = 0
          inViewport.push([parallax, center])
        }
      }
      inViewport.forEach(([parallax, center]) => {
        parallax.style.setProperty('--o', center)
      })
    })
  }

  window.addEventListener('scroll', onScroll, { passive: true })
}
