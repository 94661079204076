import isElementInViewport from './util/isInViewport'

var d = document
function $(id) {
  return d.getElementById(id)
}

window.supportClose = () => {
  $('supportBox').style.display = 'none'
}

window.supportMore = () => {
  $('supportDefault').style.display = 'none'
  $('supportMore').style.display = 'block'
}

window.supportOpen = () => {
  $('supportMore').style.display = 'none'
  $('supportForm').style.display = 'block'
}

window.openMenu = () => {
  $('menu-screen').classList.add('control--open')
  $('overlay').classList.add('overlay--active')
  $('themes-select').style.zIndex = '5'
}

window.closeMenu = () => {
  $('menu-screen').classList.remove('control--open')
  $('overlay').classList.remove('overlay--active')
  $('themes-select').style.zIndex = '11'
}

window.openLangMenu = () => {
  $('menu-lang').classList.add('control__lang--open')
}

window.closeLangMenu = () => {
  $('menu-lang').classList.remove('control__lang--open')
}

var footerSelect = d.getElementsByClassName('footer__select')

for (var i = 0; i < footerSelect.length; i++) {
  footerSelect[i].onclick = function(event) {
    this.classList.toggle('footer__select--active')
  }
}

var elementsToShow = d.querySelectorAll('.show-on-scroll')
var header = $('header')
var logo = $('logo')
var lastScrollPosition = 0

function onScrollHeader() {
  scrollPosition = d.body.scrollTop || d.documentElement.scrollTop
  if (scrollPosition > 150 && lastScrollPosition <= 150) {
    header.classList.add('header--down')
    header.classList.remove('header--up')
  } else if (scrollPosition <= 150 && lastScrollPosition > 150) {
    header.classList.remove('header--down')
    header.classList.add('header--up')
  }

  if (scrollPosition > 5850 && lastScrollPosition <= 5850) {
    logo.classList.add('header__logo--hidden')
    logo.classList.remove('header__logo--shown')
  } else if (scrollPosition <= 5850 && lastScrollPosition > 5850) {
    logo.classList.add('header__logo--shown')
    logo.classList.remove('header__logo--hidden')
  }

  lastScrollPosition = scrollPosition
}

export function recalculateScroll() {
  elementsToShow = d.querySelectorAll('.show-on-scroll')
  onScrollShow()
}

function onScrollShow() {
  var toShow = []
  var toHide = []
  var toStop = false

  elementsToShow.forEach(function(element) {
    if (isElementInViewport(element).isInViewport) {
      toShow.push(element)
    } else {
      toHide.push(element)
    }
  })

  toShow.forEach(function(element) {
    element.classList.add('is-visible')
    if (element.classList.contains('keep-on-scroll')) {
      element.classList.remove('show-on-scroll')
      toStop = true
    }
  })

  toHide.forEach(function(element) {
    element.classList.remove('is-visible')
  })

  if (toStop) {
    elementsToShow = d.querySelectorAll('.show-on-scroll')
  }
}

function onScroll() {
  onScrollHeader()
  onScrollShow()
}

if (header || elementsToShow.length) {
  window.addEventListener('scroll', onScroll, { passive: true })
  onScroll()
}
