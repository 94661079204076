const d = document
const w = window
const wrapper = d.getElementById('overflow')
let isOpen = false

function open(idTheme) {
  wrapper.classList.add('overflow--active')
  wrapper.querySelector('input[name="idTheme"]').value = idTheme || ''
  d.body.style.position = 'fixed'
  isOpen = true
}

function close() {
  wrapper.classList.remove('overflow--active')
  d.body.style.position = 'static'
  isOpen = true
}

// aktivace-overflow
d.addEventListener('click', event => {
  const opener = event.target.closest('.signin-overflow')
  if (!opener) {
    return
  }
  event.preventDefault()
  const idTheme = opener.getAttribute('data-theme')
  open(idTheme)
  historyPush({ login: true, idTheme: idTheme })
})

var closeButton = d.querySelector('.control__close')
if (closeButton) {
  closeButton.addEventListener('click', function() {
    close()
    historyPush({})
  })
}

function historyPush(state) {
  try {
    w.history.pushState(state, d.title, w.location)
  } catch (e) {}
}

w.addEventListener('popstate', onPopState)

function onPopState(event) {
  if (event.state && event.state.login) {
    open(event.state.idTheme)
  } else if (isOpen) {
    close()
  }
}

if (w.history.state && w.history.state.login) {
  open(w.history.state.idTheme)
}
