export default function isElementInViewport(element) {
  const { top, bottom } = element.getBoundingClientRect()
  const height = window.innerHeight || document.documentElement.clientHeight
  const isInViewport =
    (top <= 0 && bottom >= 0) ||
    (bottom >= height && top <= height) ||
    (top >= 0 && bottom <= height)

  return { isInViewport, top, bottom, height }
}
