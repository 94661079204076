const SELECTORS = {
  VIEWPORT: '.carousel__viewport',
  ITEMS: '.carousel__item',
  NAVIGATION: '.carousel__navigation',
  BUTTONS: '.carousel__navigation-button',
}

class Carousel {
  constructor(element, options = {}) {
    this.element = element
    this.viewport = element.querySelector(SELECTORS.VIEWPORT)
    this.items = element.querySelectorAll(SELECTORS.ITEMS)
    this.navigation = this.element.querySelector(SELECTORS.NAVIGATION)
    this.navigationButtons = []
    this.options = options
    this.index = 0
    this.transform = 0
    this.dragTransform = 0
    this.pointerStart = null

    this.init()
  }

  init() {
    this.element.addEventListener('touchstart', this.onDragStart)
    this.element.addEventListener('mousedown', this.onDragStart)
    window.addEventListener('resize', this.reposition)

    if (this.navigation) {
      this.navigation.classList.remove('hidden')
      this.navigationButtons = this.navigation.querySelectorAll(SELECTORS.BUTTONS)
      for (let i = 0; i < this.navigationButtons.length; i++) {
        this.navigationButtons[i].addEventListener('click', () => {
          this.goTo(i)
        })
      }
      this.navigationButtons[0].classList.add('active')
    }
  }

  getEventHorizontalPosition(event) {
    return event.touches?.[0].clientX || event.clientX
  }

  update() {
    if (this.dragTransform) {
      this.viewport.style.transition = 'none'
    } else {
      this.viewport.style.transition = 'transform 300ms ease-out'
    }
    const translate = this.transform + this.dragTransform
    this.viewport.style.transform = `translateX(${translate}px)`

    for (let button of this.navigationButtons) {
      if (button === this.navigationButtons[this.index]) {
        button.classList.add('active')
      } else {
        button.classList.remove('active')
      }
    }
  }

  reposition = () => {
    const viewportClientRect = this.viewport.getBoundingClientRect()
    const position = viewportClientRect.left - this.transform
    let min = Infinity
    let match = null
    let index = 0
    for (let i = 0; i < this.items.length; i++) {
      const clientRect = this.items[i].getBoundingClientRect()
      const itemPosition = Math.abs(clientRect.left - position)
      if (itemPosition < min) {
        min = itemPosition
        match = clientRect
        index = i
      }
    }
    this.transform -= match.left - position
    this.index = index
    this.update()
  }

  goTo(index) {
    const viewportClientRect = this.viewport.getBoundingClientRect()
    const position = viewportClientRect.left - this.transform
    const clientRect = this.items[index].getBoundingClientRect()

    this.index = index
    this.transform -= clientRect.left - position
    this.update()
  }

  onDragStart = event => {
    this.pointerStart = this.getEventHorizontalPosition(event)
    document.addEventListener('mousemove', this.onDrag)
    document.addEventListener('mouseup', this.onDragEnd)
    document.addEventListener('touchmove', this.onDrag)
    document.addEventListener('touchend', this.onDragEnd)
  }

  onDrag = event => {
    const position = this.getEventHorizontalPosition(event)
    this.dragTransform = position - this.pointerStart
    this.update()
  }

  onDragEnd = event => {
    document.removeEventListener('mousemove', this.onDrag)
    document.removeEventListener('mouseup', this.onDragEnd)
    document.removeEventListener('touchmove', this.onDrag)
    document.removeEventListener('touchend', this.onDragEnd)

    if (Math.abs(this.dragTransform) < 8) {
      this.dragTransform = 0
      this.goTo((this.index + 1) % this.items.length)
      return
    }
    this.transform += this.dragTransform
    this.dragTransform = 0
    this.reposition()
  }
}

const carousels = document.querySelectorAll('.carousel')
for (let carousel of carousels) {
  new Carousel(carousel)
}
