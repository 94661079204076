function autoScroll(store, interval) {
  const callback = () => {
    window.requestAnimationFrame(() => {
      store.dispatch({ type: 'AUTOSCROLL' })
      timeout = setTimeout(callback, interval)
    })
  }
  let timeout = setTimeout(callback, interval)
  return () => {
    clearTimeout(timeout)
    return autoScroll(store, interval)
  }
}

export function createAutoScroll(store, interval) {
  let effect = autoScroll(store, interval)
  return function(state, action) {
    switch (action.type) {
      case 'LEFT':
      case 'RIGHT':
      case 'GOTO':
        effect = effect()
        return
      default:
        return
    }
  }
}

export function createUnblock(store, duration) {
  return function unblock(state, action) {
    if (action.type && action.type !== 'ANIMATION_END') {
      setTimeout(() => {
        store.dispatch({ type: 'ANIMATION_END' })
      }, duration)
    }
  }
}
