import { h, Component, render } from 'preact'
import { createStore } from './slider/store'
import { createAutoScroll, createUnblock } from './slider/effects'
import { isIdle } from './slider/selectors'
import { createReducer } from './slider/reducers'

const container = document.getElementById('cascade-slider')
if (container) {
  const root = document.getElementById('cascade-slider-target')
  const path = root.getAttribute('data-root')
  const elements = Array.from(container.children)
  let count = elements.length
  let fill = 0
  const originalCount = count
  const ANIMATION_DURATION = 500
  const ANIMATION_INTERVAL = 10000

  matchMediaList = window.matchMedia('(max-width: 880px)')
  const defaultVisible = matchMediaList.matches ? 1 : 2

  while (count < 8 || count % originalCount !== 0) {
    const clone = elements[fill++].cloneNode(true)
    elements.push(clone)
    container.appendChild(clone)
    count++
  }

  const store = createStore(
    {
      index: 0,
      visible: defaultVisible,
      status: 'idle',
    },
    createReducer(count),
  )

  function matchMediaListener(event) {
    store.dispatch({ type: 'RESIZE', payload: event.matches })
    store.dispatch({ type: 'GOTO', payload: 0 })
  }
  matchMediaList.addListener(matchMediaListener)

  function chunk(array, chunkSize) {
    var R = []
    for (var i = 0; i < array.length; i += chunkSize) {
      R.push(array.slice(i, i + chunkSize))
    }
    return R
  }

  class SliderComponent extends Component {
    shift() {
      const chunks = chunk(elements, this.props.visible)
      const chunkholders = Array.from(root.querySelectorAll('.cascade-slider__element'))
      chunks.forEach((chunk, i) => {
        chunk.forEach(review => chunkholders[i].appendChild(review))
      })
    }
    componentDidUpdate(prevProps) {
      if (this.props.visible !== prevProps.visible) {
        this.shift()
      }
    }
    componentDidMount() {
      this.shift()
    }
    render({ index, visible }) {
      const currentCount = Math.ceil(count / visible)
      const prev = (index - 1 + currentCount) % currentCount
      const self = index
      const next = (index + 1) % currentCount
      const toIn = (index + 2) % currentCount
      const chunks = chunk(elements, visible)
      return (
        <div className="show-rank__wrap">
          <div className="show-rank relative">
            {chunks.map((chunk, index) => {
              const isPrev = index === prev
              const isSelf = index === self
              const isNext = index === next
              const isToIn = index === toIn
              const suffix = isPrev ? '1' : isSelf ? '2' : isNext ? '3' : isToIn ? '4' : null
              const className = suffix ? 'rank-theme__box--' + suffix : ''
              return (
                <div
                  className={`cascade-slider__element rank-theme__box sh--secondary ${className}`}
                />
              )
            })}
            <div className="rank__arrow-box rank__arrow-box--left" onClick={goLeft}>
              <img className="rank__arrow" src={`${path}arrow.svg`} alt="Přejít na předchozí" />
            </div>
            <div className="rank__arrow-box rank__arrow-box--right" onClick={goRight}>
              <img className="rank__arrow" src={`${path}arrow.svg`} alt="Přejít na další" />
            </div>
          </div>
        </div>
      )
    }
  }

  function goLeft() {
    if (isIdle(store.getState())) {
      store.dispatch({ type: 'LEFT' })
    }
  }
  function goRight() {
    if (isIdle(store.getState())) {
      store.dispatch({ type: 'RIGHT' })
    }
  }

  function shift(state) {
    const { index, visible } = state
    render(<SliderComponent index={index} visible={visible} />, root)
  }

  var autoScroll = createAutoScroll(store, ANIMATION_INTERVAL)
  var unblock = createUnblock(store, ANIMATION_DURATION)
  var tick = function(state, action) {
    shift(state, action)
    unblock(state, action)
    autoScroll(state, action)
  }

  store.subscribe(tick)
  tick(store.getState(), {})
  container.remove()
}
