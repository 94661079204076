export function createReducer(totalCount) {
  return function reducer(state, action) {
    const count = Math.ceil(totalCount / state.visible)
    switch (action.type) {
      case 'LEFT':
        return {
          ...state,
          index: (state.index - 1 + count) % count,
          status: 'running',
        }
      case 'RIGHT':
        return {
          ...state,
          index: (state.index + 1) % count,
          status: 'running',
        }
      case 'AUTOSCROLL':
        return {
          ...state,
          index: (state.index + 1) % count,
          status: 'running',
        }
      case 'GOTO':
        return {
          ...state,
          index: action.payload,
          status: 'running',
        }
      case 'ANIMATION_END':
        return {
          ...state,
          status: 'idle',
        }
      case 'RESIZE':
        return {
          ...state,
          index: Math.floor((state.index * state.visible) / (action.payload ? 1 : 2)),
          visible: action.payload ? 1 : 2,
        }
      default:
        return state
    }
  }
}
